export class StudyDetail {
    constructor(
        lesson,
        time,
        attendance,
        rating,
        homeworkStatus,
        comments,
        commentPhase,
        type,
        totalLesson,
        studentFeedback,
        autoComment) {
        this.lesson = lesson;
        this.time = time;
        this.attendance = this.attendanceToString(attendance)
        this.rating = rating;
        this.homeworkStatus = homeworkStatus
        this.comments = comments
        this.commentPhase = commentPhase
        this.type = type
        this.studentFeedback = studentFeedback
        this.autoComment = autoComment
    }
    attendanceToString (item) {
        switch (item) {
            case 0:
                return 'Vắng mặt';
            case 1:
                return 'Có mặt';
            case 2:
                return 'Có phép';
            case 3:
                return 'Ngừng học';
            default:
                return null
        }
    }
}


